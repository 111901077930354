import React from 'react';
import Layout from 'components/Layout';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
// import Faq from 'components/Faq';
import ArchivePostSingle from 'components/ArchivePostSingle';
import Meta from 'components/Meta';
import SupportHeader from 'components/SupportHeader';
import Scrollspy from 'components/Scrollspy';
// import theme from 'styles/theme';
// import Main from 'styles/supportInner';
// import { BlogListSupport } from 'styles/support';
import SupportInnerNav from '../../components/SupportInnerNav/SupportInnerNav';
import 'styles/supportInner.scss';
const SupportBillingAndPlans = ({
  data: {
    allContentfulBlogPost: { edges: allPosts = [] },
  },
  location,
}) => (
  <Layout hideHeader location={location}>
    {({ toggleContactUs }) => (
      <>
        <Meta
          title="Support documentation 🔨 | ruttl"
          url="https://ruttl.com/support/"
        />
        <main className="supportinner-styed-main">
          <SupportHeader toggleContactUs={toggleContactUs} />
          <section className="supportinner-hero">
            <div className="container">
              <h1>Billing and plans:</h1>
            </div>
          </section>
          <SupportInnerNav />
          <section className="support-inner-block">
            <Scrollspy>
              <div className="flex-1 support-inner-content">
                <img src="/assets/img/support-get-started.png" alt="" />
                <h2 data-spy-title="Free account" data-spy-id="free-account">
                  Free account
                </h2>
                <p>
                  With your free account, you will be able to create and access
                  one (1) project, and will be able to add up to five (5)
                  collaborators in this project. You will be able to access the
                  Edit mode for a period of two (2) days only, post which, you
                  will have to upgrade your plan to access the edit mode.
                </p>
                <h3
                  data-spy-title="How to upgrade"
                  data-spy-id="how-to-upgrade">
                  How to upgrade
                </h3>
                <p>
                  You can upgrade your account once the existing project limit
                  is exhausted, or when you wish to access the full fledged edit
                  mode. You can upgrade in the following ways:
                </p>
                <ul>
                  <li>
                    Go to the main menu on the top right side &gt; My plan &gt;
                    Upgrade &gt; Select Standard, Pro or Team plan in Monthly or
                    Yearly format, make the payment, and your account will be
                    upgraded once we successfully receive the payment.
                  </li>

                  <li>
                    Visit our Lifetime&nbsp;
                    <Link to="/pricing/">Privilege Plans</Link>
                    &nbsp;page to know more about our lifetime deals.
                  </li>

                  <li>
                    You can also ask us to manually upgrade your account, by
                    sending us an email to
                    <a href="mailto:support@ruttl.com"> support@ruttl.com.</a>
                    We will take your payment information through PayPal, and
                    shall then be able to upgrade your account. You can also ask
                    us to manually upgrade your account, by sending us an email
                    to&nbsp;
                  </li>
                </ul>
                <h3
                  data-spy-title="Edit mode under freemium policy"
                  data-spy-id="edit-mode-under-freemium-policy">
                  Edit mode under freemium policy
                </h3>
                <p>
                  Under our new freemium policy, all users will be able to
                  access the Edit mode only for a period of two (2) days. After
                  this, if you wish to access the Edit mode, please upgrade your
                  account using one of the above options.
                </p>
                <h3 data-spy-title="Refund policy" data-spy-id="refund-policy">
                  Refund policy
                </h3>
                <p>
                  If you have bought our&nbsp;
                  <span className="bold">annual plan </span>
                  or&nbsp;
                  <span className="bold">subscription </span>
                  within the last 7 days, you are eligible to get a full refund.
                  There is&nbsp;
                  <span className="bold">
                    no refund for monthly subscriptions.
                  </span>
                </p>
              </div>
            </Scrollspy>
          </section>
          <main className="blog-list-support">
            <section
              className="bg-lightGrey"
              style={{
                // backgroundColor: theme.colors.lightGrey,
                padding: '10px 0',
              }}>
              <div className="container">
                <div className="other-articles-block">
                  <h3>Popular Articles</h3>
                  <ul>
                    {allPosts.map(({ node }) => (
                      <li key={node.id}>
                        <ArchivePostSingle
                          link={`/blog/${node.slug}/`}
                          title={node.title}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
          </main>
          {/* <Faq id="faq" /> */}
        </main>
      </>
    )}
  </Layout>
);

SupportBillingAndPlans.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default SupportBillingAndPlans;

export const pageQuery = graphql`
  query SupportBillingAndPlansQuery {
    allContentfulBlogPost(
      filter: { knowledgeBox: { eq: false } }
      limit: 10
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMM DD, YYYY")
          archiveThumbnail {
            fluid(
              maxWidth: 600
              maxHeight: 456
              quality: 70
              resizingBehavior: THUMB
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          excerpt {
            excerpt
          }
          knowledgeBox
          category {
            name
          }
        }
      }
    }
  }
`;
